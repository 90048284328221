@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
body{
	background: url(/fondo.png) no-repeat center bottom fixed;
 background-size: cover;
	color: #f1f1f1 !important;
	font-family: 'Roboto', sans-serif !important;
  padding-top: 130px;
  background-color: #4A4A4A;

}

img{ max-width: 180px; padding-bottom: 50px;}

h1{ font-weight: 100; font-size: 30px; margin-bottom: 50px ; }
h1 strong{ font-weight: 400 }

h2{ font-weight: 300; font-size: 26px }

.contacto{ padding-top: 50px; font-weight: 100; font-size: 18px }
.contacto i{ padding-right: 5px;  font-weight: 100;  }

a { color: white; }
a:hover { 
    color: white;
}
@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1199px) {}


@media screen and (max-width: 991px) {
	body{ padding-top: 50px; }
	h1{ font-size: 26px; }
	h2{ font-size: 22px; }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 530px) {
	
}

@media screen and (max-width: 480px) {}
